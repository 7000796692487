import type { SxProps } from "@infinitaslearning/pixel-design-system";
import type { Theme } from "@mui/material/styles";
import type { TFunction } from "i18next";

export type PlatformErrorTypes =
  | "other"
  | "unauthenticated"
  | "unauthorized"
  | "data"
  | "role"
  | "locked_lu"
  | "navigation"
  | "player_content";

export enum PlatformErrorCodes {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Locked = 423,
  ServerError = 500,
  Unknown = 0,
}

export type PlatformErrorDisplayTypes = "notification" | "responsive" | "responsive_full" | "full";

export type ErrorComponentProps = {
  errorType: PlatformErrorTypes;
  title: string;
  t: TFunction;
  errorCode?: PlatformErrorCodes;
  customMessage?: string;
  message: string;
  children?: React.ReactNode;
  CTA: JSX.Element | null;
  sx?: SxProps<Theme>;
};

export const CENTERED_SX = {
  justifyContent: "center",
  alignItems: "center",
};

// Should be matched by lang JSON file: common.platformError.data-types content
export type DataErrorTypes =
  | "unknown"
  | "license"
  | "material"
  | "user"
  | "diff"
  | "classroom"
  | "students"
  | "results"
  | "news"
  | "observations"
  | "analyze";
